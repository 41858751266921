import React, { useEffect, useState } from "react";
import classes from "./Header.module.css";
import Burger from "./Navigation/Navigation/Burger";
import Navigation from "./Navigation/Navigation/Navigation";
import { Link } from "react-router-dom";
import axios from "../../axios";
import { apiUrl } from "../../api";

const Header = (props) => {
  const [navOpen, setNavOpen] = useState(false);
  const [headerData, setHeaderData] = useState({});

  useEffect(() => {
    if (navOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [navOpen]);

  useEffect(() => {
    axios.get("/singletons/get/header").then((res) => {
      setHeaderData(res.data);
    });
  }, []);

  const cls = [classes.Header];
  if (props.small) {
    cls.push(classes.Small);
  }

  return (
    <header className={cls.join(" ")}>
      <div
        style={{ backgroundImage: `url(${apiUrl}${headerData?.image?.path})` }}
        className={classes.BackgroundImage}
      >
        <div className={classes.Filter}></div>
      </div>

      <Link to="/">
        <img
          className={classes.Logo}
          src={apiUrl + headerData?.logo?.path}
          alt="Logo"
        />
      </Link>
      <Burger open={navOpen} onClick={() => setNavOpen(!navOpen)} />
      <Navigation closeNav={() => setNavOpen(false)} open={navOpen} />
      <div
        className={
          navOpen ? classes.Backdrop + " " + classes.Open : classes.Backdrop
        }
      ></div>
      <h2>
        <span>{headerData.title}</span>
        <span className={classes.Small}>{headerData.subtitle}</span>
      </h2>
    </header>
  );
};

export default Header;
