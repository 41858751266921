import React from "react";
import classes from "./Navigation.module.css";

import home from "../../../../_res/nav/home.png";
import arrow from "../../../../_res/nav/arrow.png";
import bars from "../../../../_res/nav/bars.png";
import bell from "../../../../_res/nav/bell.png";
import calendar from "../../../../_res/nav/calendar.png";
import car from "../../../../_res/nav/car.png";
import clock from "../../../../_res/nav/clock.png";
import people from "../../../../_res/nav/people.png";
import pin from "../../../../_res/nav/pin.png";
import star from "../../../../_res/nav/star.png";
import link from "../../../../_res/nav/link.png";
import { NavLink } from "react-router-dom";

const Navigation = (props) => {
  const cls = [classes.Navigation];
  if (props.open) cls.push(classes.Open);

  return (
    <section className={cls.join(" ")}>
      <div style={{ paddingBottom: "100px" }}>
        <NavLink
          onClick={props.closeNav}
          className={({ isActive }) =>
            classes.NavLink + (isActive ? " " + classes.Active : "")
          }
          to="/"
        >
          <img src={home} alt="home" />
          <p>Startseite</p>
        </NavLink>
        <NavLink
          onClick={props.closeNav}
          className={({ isActive }) =>
            classes.NavLink + (isActive ? " " + classes.Active : "")
          }
          to="/aktuell"
        >
          <img src={bell} alt="bell" />
          <p>Aktuell</p>
        </NavLink>
        <NavLink
          onClick={props.closeNav}
          className={({ isActive }) =>
            classes.NavLink + (isActive ? " " + classes.Active : "")
          }
          to="/termine"
        >
          <img src={calendar} alt="calendar" />
          <p>Termine / Anlässe</p>
        </NavLink>
        <NavLink
          onClick={props.closeNav}
          className={({ isActive }) =>
            classes.NavLink + (isActive ? " " + classes.Active : "")
          }
          to="/rueckblick"
        >
          <img src={clock} alt="clock" />
          <p>Rückblick / Galerie</p>
        </NavLink>
        <NavLink
          onClick={props.closeNav}
          className={({ isActive }) =>
            classes.NavLink + (isActive ? " " + classes.Active : "")
          }
          to="/anschlagbrett"
        >
          <img src={pin} alt="pin" />
          <p>Anschlagbrett</p>
        </NavLink>
        <NavLink
          onClick={props.closeNav}
          className={({ isActive }) =>
            classes.NavLink + (isActive ? " " + classes.Active : "")
          }
          to="/stammtisch"
        >
          <img src={car} alt="car" />
          <p>Stammtische</p>
        </NavLink>
        <NavLink
          onClick={props.closeNav}
          className={({ isActive }) =>
            classes.NavLink + (isActive ? " " + classes.Active : "")
          }
          to="/vorstand"
        >
          <img src={people} alt="people" />
          <p>Vorstand</p>
        </NavLink>
        <NavLink
          onClick={props.closeNav}
          className={({ isActive }) =>
            classes.NavLink + (isActive ? " " + classes.Active : "")
          }
          to="/mitglied-werden"
        >
          <img src={star} alt="star" />
          <p>Mitglied werden</p>
        </NavLink>

        <NavLink
          onClick={props.closeNav}
          className={({ isActive }) =>
            classes.NavLink + (isActive ? " " + classes.Active : "")
          }
          to="/kontakt"
        >
          <img src={bars} alt="bars" />
          <p>Kontakt</p>
        </NavLink>
        <NavLink
          onClick={props.closeNav}
          className={({ isActive }) =>
            classes.NavLink + (isActive ? " " + classes.Active : "")
          }
          to="/links"
        >
          <img src={link} alt="links" />
          <p>Links</p>
        </NavLink>
      </div>
    </section>
  );
};

export default Navigation;
