import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import classes from "./Aktuell.module.css";

import axios from "../../axios";
import { apiUrl } from "../../api";
import Header from "../../Layout/Header/Header";

const AktuellDetails = (props) => {
  const { id } = useParams();
  const [detaills, setDetaills] = useState({});

  useEffect(() => {
    axios
      .get(`/collections/get/news?filter[_id]=${id.split("-")[1]}`)
      .then((res) => {
        setDetaills(res.data.entries[0]);
      });
  }, [id]);

  return (
    <div>
      <Header small={true} />
    <main>
      <section className={classes.Controls}>
        <Link to="/aktuell">&lt; Zurück</Link>
        <label>{detaills.date}</label>
      </section>
      <img
        style={{ width: "100%" }}
        src={`${apiUrl}/${detaills?.image?.path}`}
        alt="Auto"
      />
      <div dangerouslySetInnerHTML={{ __html: detaills.text }}></div>
    </main>
    </div>
  );
};

export default AktuellDetails;
