import React from "react";
import classes from "./Navigation.module.css";

const Burger = (props) => {
  const cls = [classes.Burger];
  if (props.open) cls.push(classes.Open);

  return (
    <div onClick={() => props.onClick()} className={cls.join(" ")}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Burger;
