import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Aktuell from "./Pages/Aktuell/Aktuell";
import AktuellDetails from "./Pages/Aktuell/AktuellDetails";
import Termine from "./Pages/Termine/Termine";
import TermineDetails from "./Pages/Termine/TermineDetails";
import Anschlagbrett from "./Pages/Anschlagbrett/Anschlagbrett";
import AnschlagbrettDetails from "./Pages/Anschlagbrett/AnschlagbrettDetails";
import Vorstand from "./Pages/Vorstand/Vorstand";
import Kontakt from "./Pages/Kontakt/Kontakt";
import Treffen from "./Pages/Treffen/Treffen";
import TreffenDetails from "./Pages/Treffen/TreffenDetails";
import Stammtisch from "./Pages/Stammtisch/Stammtisch";
import Mitglied from "./Pages/Mitglied/Mitglied";
import Links from "./Pages/Links/Links";
import Rueckblick from "./Pages/Rueckblick/Rueckblick";
import RueckblickDetails from "./Pages/Rueckblick/RueckblickDetails";
import Impressum from "./Pages/Impressum/Impressum";
import Datenschutz from "./Pages/Datenschutz/Datenschutz";
import Footer from "./Layout/Footer/Footer";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/aktuell" element={<Aktuell />} />
          <Route exact path="/aktuell/:id" element={<AktuellDetails />} />
          <Route exact path="/termine" element={<Termine />} />
          <Route exact path="/termine/:id" element={<TermineDetails />} />
          <Route exact path="/anschlagbrett" element={<Anschlagbrett />} />
          <Route exact path="/rueckblick" element={<Rueckblick />} />
          <Route exact path="/rueckblick/:id" element={<RueckblickDetails />} />
          <Route exact path="/vorstand" element={<Vorstand />} />
          <Route exact path="/kontakt" element={<Kontakt />} />
          <Route exact path="/links" element={<Links />} />
          <Route exact path="/stammtisch" element={<Stammtisch />} />
          <Route exact path="/treffen" element={<Treffen />} />
          <Route exact path="/treffen/:id" element={<TreffenDetails />} />
          <Route exact path="/mitglied-werden" element={<Mitglied />} />
          <Route exact path="/impressum" element={<Impressum />} />
          <Route exact path="/datenschutz" element={<Datenschutz />} />
          <Route
            exact
            path="/anschlagbrett/:id"
            element={<AnschlagbrettDetails />}
          />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
