import React, { useEffect, useState } from "react";
import Tile from "../../Components/Tile/Tile";
import Button from "../../Components/Button/Button";

import { Link } from "react-router-dom";
import axios from "../../axios";
import { apiUrl } from "../../api";
import Header from "../../Layout/Header/Header";

const Home = (props) => {
  const [headingData, setHeadingData] = useState({});
  const [news, setNews] = useState({});
  const [dates, setDates] = useState([]);
  const [bulletins, setBulletins] = useState([]);

  useEffect(() => {
    axios.get("/singletons/get/home").then((res) => {
      setHeadingData(res.data);
    });

    axios.get("/collections/get/news").then((res) => {
      setNews(res.data.entries[0]);
    });

    axios.get("/collections/get/bulletins").then((res) => {
      setBulletins(res.data.entries[0]);
    });

    axios.get("/collections/get/date").then((res) => {
      const f = res.data.entries.filter(d => d.active);
      const tmp =
        f.length > 2
          ? [f[0], f[1]]
          : f;
      
      setDates(tmp);
    });
  }, []);

  return (
    <div>
      <Header />
      <main>
        <div dangerouslySetInnerHTML={{ __html: headingData.text }}></div>
        {headingData.show_current ? (
          <section>
            <h2>Aktuell</h2>
            <hr />
            <Tile
              image={news?.image?.path ? `${apiUrl}/${news?.image?.path}` : false}
              to={`/aktuell/artikel-${news._id}`}
              title={news.title}
            >
              {news.short}
            </Tile>
            <Link to="/aktuell">
              <Button onClick={() => null} style={{ marginTop: "20px" }}>
                Alle News
              </Button>
            </Link>
          </section>
        ) : null}
        {headingData.show_meetings ? (
          <section>
            <h2>Termine</h2>
            <hr />

            {dates.map((d) => (
              <Tile
                key={d._id}
                date={d.date}
                title={d.title}
                to={`/termine/termin-${d._id}`}
              >
                {d.short}
              </Tile>
            ))}

            <Link to="/termine">
              <Button onClick={() => null} style={{ marginTop: "20px" }}>
                Alle Termine
              </Button>
            </Link>
          </section>
        ) : null}
        {headingData.show_pinwall ? (
          <section>
            <h2>Anschlagbrett</h2>
            <hr />
            <Tile
              image={
                bulletins?.image?.path
                  ? `${apiUrl}/${bulletins?.image?.path}`
                  : false
              }
              to={`/anschlagbrett/artikel-${bulletins._id}`}
              title={bulletins.title}
            >
              {bulletins.short}
            </Tile>
            <Link to="/anschlagbrett">
              <Button
                onClick={() => null}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                Alle anzeigen
              </Button>
            </Link>
          </section>
        ) : null}
      </main>
    </div>
  );
};

export default Home;
