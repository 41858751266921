import classes from "./Field.module.css";

export default function Field(props) {
  const cls = [classes.Field];
  if (props.error) {
    cls.push(classes.Error);
  }

  return (
    <div className={classes.Container}>
      <label className={classes.Label}>{props.label}</label>
      <input
        type="text"
        name={props.name}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e)}
        onBlur={(e) => props.onBlur(e)}
        value={props.value}
        className={cls.join(" ")}
      />
    </div>
  );
}
