import React, { useEffect, useState } from "react";
import Tile from "../../Components/Tile/Tile";

import axios from "../../axios";
import { apiUrl } from "../../api";
import Header from "../../Layout/Header/Header";

const Aktuell = (props) => {
  const [news, setNews] = useState([]);
  const [details, setDetails] = useState({});

  useEffect(() => {
    axios.get("/collections/get/news/").then((res) => {
      setNews(res.data.entries.filter((e) => e.active));
    });

    axios.get("/singletons/get/news/").then((res) => {
      setDetails(res.data);
    });
  }, []);

  return (
    <div>
      <Header small={true} />
      <main>
        <section dangerouslySetInnerHTML={{ __html: details.text }}></section>

        {news.map((n) => (
          <Tile
            key={n._id}
            image={`${apiUrl}/${n.image?.path}`}
            title={n.title}
            to={`/aktuell/artikel-${n._id}`}
          >
            {n.short}
          </Tile>
        ))}
      </main>
    </div>
  );
};

export default Aktuell;
