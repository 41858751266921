import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Tile from "../../Components/Tile/Tile";
import Header from "../../Layout/Header/Header";

const Treffen = (props) => {
  const [meetings, setMeetings] = useState([]);
  const [details, setDetails] = useState({});

  useEffect(() => {
    axios.get("/collections/get/meetings/").then((res) => {
      setMeetings(res.data.entries.filter((e) => e.active));
    });

    axios.get("/singletons/get/meetings/").then((res) => {
      setDetails(res.data);
    });
  }, []);

  return (
    <div>
      <Header small={true} />
      <main>
        <section dangerouslySetInnerHTML={{ __html: details.text }}></section>

        {meetings.map((n) => (
          <Tile
            key={n._id}
            title={n.title}
            date={n.date}
            to={`/treffen/treffen-${n._id}`}
          >
            {n.short}
          </Tile>
        ))}
      </main>
    </div>
  );
};

export default Treffen;
