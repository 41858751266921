import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Header from "../../Layout/Header/Header";

const Impressum = (props) => {
  const [headingText, setHeadingText] = useState("");

  useEffect(() => {
    axios.get("/singletons/get/imprint").then((res) => {
      setHeadingText(res.data.content);
    });
  }, []);

  return (
    <div>
      <Header small={true} />
      <main>
        <div dangerouslySetInnerHTML={{ __html: headingText }}></div>
      </main>
    </div>
  );
};

export default Impressum;
