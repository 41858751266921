import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import classes from "./Rueckblick.module.css";

import axios from "../../axios";
import { apiUrl } from "../../api";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import Header from "../../Layout/Header/Header";

const RueckblickDetails = (props) => {
  const { id } = useParams();
  const [details, setDetaills] = useState({});

  useEffect(() => {
    axios
      .get(`/collections/get/retrospects?filter[_id]=${id.split("-")[1]}`)
      .then((res) => {
        setDetaills(res.data.entries[0]);
      });
  }, [id]);

  const preventContext = () => {
    const imgs = document.querySelectorAll(".lg-object");
    imgs.forEach((i) => {
      i.addEventListener("contextmenu", (e) => e.preventDefault());
    });
  };

  return (
    <div>
      <Header small={true} />
      <main>
        <section className={classes.Controls}>
          <Link to="/rueckblick">&lt; Zurück</Link>
          <label>{details?.date}</label>
        </section>

        <div dangerouslySetInnerHTML={{ __html: details.text }}></div>

        <section className={classes.LightGallery}>
          <LightgalleryProvider
            onAfterSlide={preventContext}
            galleryClassName="LightGallery"
          >
            {details?.image
              ? details?.image.map((i, k) => (
                  <LightgalleryItem
                    src={`${apiUrl}/${i.path}`}
                    group="LightGallery"
                    key={k}
                  >
                    <div
                      onContextMenu={(e) => e.preventDefault()}
                      className={classes.Thumbnail}
                      style={{ backgroundImage: `url('${apiUrl}/${i.path}')` }}
                      alt={`${i.meta?.title}`}
                    ></div>
                  </LightgalleryItem>
                ))
              : null}
          </LightgalleryProvider>
        </section>
      </main>
    </div>
  );
};

export default RueckblickDetails;
