import axios from "../../axios";
import React, { useEffect, useState } from "react";
import BoardMember from "../../Components/BoardMember/BoardMember";
import { apiUrl } from "../../api";
import Header from "../../Layout/Header/Header";

const Vorstand = (props) => {
  const [members, setMembers] = useState([]);
  const [details, setDetails] = useState({});

  useEffect(() => {
    axios.get("/collections/get/boardmembers").then((res) => {
      setMembers(res.data.entries);
    });

    axios.get("/singletons/get/vorstand").then((res) => {
      setDetails(res.data);
    });
  }, []);

  return (
    <div>
      <Header small={true} />
      <main>
        <section dangerouslySetInnerHTML={{ __html: details?.text }}></section>

        {members.map((m) => (
          <BoardMember
            key={m._id}
            image={`${apiUrl}/${m.photo.path}`}
            phone={m.phone}
            mail={m.mail}
            role={m.role}
            name={m.name}
          >
            {m.description}
          </BoardMember>
        ))}
      </main>
    </div>
  );
};

export default Vorstand;
