import React from "react";
import classes from "./BoardMember.module.css";

const BoardMember = (props) => {
  return (
    <article className={classes.BoardMember}>
      <div className={classes.Info}>
        <div className={classes.Photo} style={{ backgroundImage: `url(${props.image})` }}></div>
        <div>
          <h3>{props.name}</h3>
          <p>{props.role}</p>
          <p>{props.mail}</p>
          <p>{props.phone}</p>
        </div>
      </div>
      <p>{props.children}</p>
    </article>
  );
};

export default BoardMember;
