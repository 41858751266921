import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import classes from "./Termine.module.css";

import axios from "../../axios";
import Header from "../../Layout/Header/Header";

const TermineDetails = (props) => {
  const { id } = useParams();
  const [detaills, setDetaills] = useState({});

  useEffect(() => {
    axios
      .get(`/collections/get/date?filter[_id]=${id.split("-")[1]}`)
      .then((res) => {
        setDetaills(res.data.entries[0]);
      });
  }, [id]);

  return (
    <div>
      <Header small={true} />
      <main>
        <section className={classes.Controls}>
          <Link to="/termine">&lt; Zurück</Link>
        </section>
        <div dangerouslySetInnerHTML={{ __html: detaills.text }}></div>
      </main>
    </div>
  );
};

export default TermineDetails;
