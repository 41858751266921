import { useEffect } from "react";
import { useState } from "react";
import axios from "../../axios";
import classes from "./Footer.module.css";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {
    axios.get("/singletons/get/footer").then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <footer className={classes.Footer}>
      {data.imprint ? <Link to="/impressum">Impressum</Link> : null}
      {data.privacy ? <Link to="/datenschutz">Datenschutz</Link> : null}
    </footer>
  );
};

export default Footer;
