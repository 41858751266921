import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Tile from "../../Components/Tile/Tile";
import Header from "../../Layout/Header/Header";

const Termine = (props) => {
  const [dates, setDates] = useState([]);
  const [details, setDetails] = useState({});

  useEffect(() => {
    axios.get("/collections/get/date/").then((res) => {
      setDates(res.data.entries.filter((e) => e.active));
    });

    axios.get("/singletons/get/date/").then((res) => {
      setDetails(res.data);
    });
  }, []);

  return (
    <div>
      <Header small={true} />
      <main>
        <section dangerouslySetInnerHTML={{ __html: details.text }}></section>
        <section>
          {dates.map((n) => (
            <Tile
              key={n._id}
              title={n.title}
              date={n.date}
              to={`/termine/termin-${n._id}`}
            >
              {n.short}
            </Tile>
          ))}
        </section>
      </main>
    </div>
  );
};

export default Termine;
