import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import classes from "./Anschlagbrett.module.css";

import axios from "../../axios";
import { apiUrl } from "../../api";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Header from "../../Layout/Header/Header";

const AnschlagbrettDetails = (props) => {
  const { id } = useParams();
  const [details, setDetaills] = useState({});

  useEffect(() => {
    axios
      .get(`/collections/get/bulletins?filter[_id]=${id.split("-")[1]}`)
      .then((res) => {
        setDetaills(res.data.entries[0]);
      });
  }, [id]);

  return (
    <div>
      <Header small={true} />
      <main>
        <section className={classes.Controls}>
          <Link to="/anschlagbrett">&lt; Zurück</Link>
          <label>{details?.date}</label>
        </section>

        <Swiper
          modules={[Pagination, Navigation]}
          navigation
          pagination={{ clickable: true }}
        >
          {details?.image
            ? details?.image.map((i, k) => (
                <SwiperSlide key={k}>
                  <img
                    style={{ width: "100%" }}
                    src={`${apiUrl}/${i.path}`}
                    alt={`${i.meta?.title}`}
                  />
                </SwiperSlide>
              ))
            : null}
        </Swiper>

        <div dangerouslySetInnerHTML={{ __html: details.text }}></div>
      </main>
    </div>
  );
};

export default AnschlagbrettDetails;
