import React, { useEffect, useState } from "react";
import { apiUrl } from "../../api";
import axios from "../../axios";
import Tile from "../../Components/Tile/Tile";
import Header from "../../Layout/Header/Header";
import classes from "./Anschlagbrett.module.css";

const Anschlagbrett = (props) => {
  const [bulletins, setBulletins] = useState([]);
  const [details, setDetails] = useState({});
  const [viewSearch, setViewSearch] = useState("all");

  useEffect(() => {
    axios.get("/collections/get/bulletins/").then((res) => {
      setBulletins(res.data.entries.filter((e) => e.active));
    });

    axios.get("/singletons/get/bulletins/").then((res) => {
      setDetails(res.data);
    });
  }, []);

  return (
    <div>
      <Header small={true} />
      <main>
        <select
          className={classes.Filter}
          onChange={(e) => setViewSearch(e.target.value)}
        >
          <option value="all">Alle anzeigen</option>
          <option value="sell">Nur Verkaufen</option>
          <option value="search">Nur Suchen</option>
        </select>

        <section dangerouslySetInnerHTML={{ __html: details.text }}></section>

        {bulletins.map((n) =>
          (viewSearch === "sell" && n.is_search === false) ||
          (viewSearch === "search" && n.is_search === true) ||
          viewSearch === "all" ? (
            <Tile
              key={n._id}
              image={
                n?.image[0]?.path ? `${apiUrl}/${n?.image[0]?.path}` : false
              }
              title={n.title}
              to={`/anschlagbrett/artikel-${n._id}`}
            >
              {n.short}
            </Tile>
          ) : null
        )}
      </main>
    </div>
  );
};

export default Anschlagbrett;
