import React from "react";
import { Link } from "react-router-dom";
import classes from "./Tile.module.css";

const Tile = (props) => {
  return (
    <Link style={{textDecoration: "none"}} to={props.to}>
      <article className={classes.Tile}>
        {props.date ? <div className={classes.Date}>{props.date}</div> : null}
        {props.image ? (
          <div
            className={classes.Image}
            style={{ backgroundImage: `url(${props.image})` }}
          ></div>
        ) : null}
        <section>
          <div className={classes.Title}>{props.title}</div>
          <p className={classes.Text}>{props.children}</p>
        </section>
      </article>
    </Link>
  );
};

export default Tile;
