import axios from "../../axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../../api";
import Tile from "../../Components/Tile/Tile";
import Header from "../../Layout/Header/Header";

const Rueckblick = (props) => {
  const [members, setMembers] = useState([]);
  const [details, setDetails] = useState({});

  useEffect(() => {
    axios.get("/collections/get/retrospects").then((res) => {
      setMembers(res.data.entries);
    });

    axios.get("/singletons/get/retrospect").then((res) => {
      setDetails(res.data);
    });
  }, []);

  return (
    <div>
      <Header small={true} />
      <main>
        <section dangerouslySetInnerHTML={{ __html: details?.text }}></section>

        {members.map((n) => (
          <Tile
            key={n._id}
            image={n?.image[0]?.path ? `${apiUrl}/${n?.image[0]?.path}` : false}
            title={n.title}
            to={`/rueckblick/artikel-${n._id}`}
          >
            {n.short}
          </Tile>
        ))}
      </main>
    </div>
  );
};

export default Rueckblick;
