import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { Formik } from "formik";
import Field from "./Field/Field";
import classes from "./Mitglied.module.css";
import Header from "../../Layout/Header/Header";

const Mitglied = (props) => {
  const [headingText, setHeadingText] = useState("");

  useEffect(() => {
    axios.get("/singletons/get/member").then((res) => {
      setHeadingText(res.data.text);
    });
  }, []);

  const helper = [1, 2, 3];

  return (
    <div>
      <Header small={true} />
      <main>
        <div dangerouslySetInnerHTML={{ __html: headingText }}></div>

        <h2 className={classes.FormTitle}>Mitgliederformular</h2>
        <Formik
          initialValues={{
            firstname: "",
            lastname: "",
            email: "",
            company: "",
            birthday: "",
            street: "",
            number: "",
            zip: "",
            city: "",
            phone_private: "",
            phone_work: "",
            fax: "",
            model1: "",
            year1: "",
            vin1: "",
            model2: "",
            year2: "",
            vin2: "",
            model3: "",
            year3: "",
            vin3: "",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.email) {
              errors.email = "Pflichtfeld";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Ungültige E-Mail-Adresse";
            }

            if (!values.firstname) errors.firstname = "Pflichfeld";
            if (!values.lastname) errors.lastname = "Pflichfeld";
            if (!values.company) errors.company = "Pflichfeld";
            if (!values.birthday) errors.birthday = "Pflichfteld";
            if (!values.street) errors.street = "Pflichfteld";
            if (!values.number) errors.number = "Pflichfteld";
            if (!values.zip) errors.zip = "Pflichfteld";
            if (!values.city) errors.city = "Pflichfteld";
            //if (!values.phone_private) errors.phone_private = "Pflichfteld";
            //if (!values.phone_work) errors.phone_work = "Pflichfteld";
            //if (!values.fax) errors.fax = "Pflichfteld";
            //if (!values.model1) errors.model1 = "Pflichtfeld";
            //if (!values.year1) errors.year1 = "Pflichtfeld";
            //if (!values.vin1) errors.vin1 = "Pflichtfeld";
            //if (!values.model2) errors.model2 = "Pflichtfeld";
            //if (!values.year2) errors.year2 = "Pflichtfeld";
            //if (!values.vin2) errors.vin2 = "Pflichtfeld";
            //if (!values.model3) errors.model3 = "Pflichtfeld";
            //if (!values.year3) errors.year3 = "Pflichtfeld";
            //if (!values.vin3) errors.vin3 = "Pflichtfeld";

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            axios
              .post(
                "/forms/submit/membership?token=c8987b353e33f80ea8f90479ed01f2",
                {
                  form: {
                    ...values,
                  },
                },
                {
                  headers: { "Content-Type": "application/json" },
                }
              )
              .then((res) => {
                alert(
                  "Vielen Dank, Ihre Anmeldung ist erfolgreich bei uns eingegangen."
                );
              })
              .catch((err) => {
                alert(
                  "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut oder drucken Sie das Formular aus und füllen es manuell aus."
                );
              });

            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.FlexRow}>
                <Field
                  label="Name*"
                  placeholder="Name"
                  name="lastname"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.lastname}
                  error={errors.lastname && touched.lastname && errors.lastname}
                />

                <Field
                  label="Vorname*"
                  placeholder="Vorname"
                  name="firstname"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.firstname}
                  error={
                    errors.firstname && touched.firstname && errors.firstname
                  }
                />
              </div>
              <div className={classes.FlexRow}>
                <Field
                  label="Firma/Beruf*"
                  placeholder="Firma/Beruf"
                  name="company"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.company}
                  error={errors.company && touched.company && errors.company}
                />

                <Field
                  label="Geburtsdatum*"
                  placeholder="19.03.1978"
                  name="birthday"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.birthday}
                  error={errors.birthday && touched.birthday && errors.birthday}
                />
              </div>
              <div className={classes.FlexRow}>
                <Field
                  label="Strasse*"
                  placeholder="Strasse"
                  name="street"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.street}
                  error={errors.street && touched.street && errors.street}
                />

                <Field
                  label="Hausnummer*"
                  placeholder="Hausnummer"
                  name="number"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.number}
                  error={errors.number && touched.number && errors.number}
                />
              </div>
              <div className={classes.FlexRow}>
                <Field
                  label="Nation-Postleitzahl*"
                  placeholder="CH-1234"
                  name="zip"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.zip}
                  error={errors.zip && touched.zip && errors.zip}
                />

                <Field
                  label="Wohnort*"
                  placeholder="Wohnort"
                  name="city"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.city}
                  error={errors.city && touched.city && errors.city}
                />
              </div>
              <div className={classes.FlexRow}>
                <Field
                  label="Telefon (privat)"
                  placeholder="+41 12 123 12 13"
                  name="phone_private"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.phone_private}
                  error={
                    errors.phone_private &&
                    touched.phone_private &&
                    errors.phone_private
                  }
                />

                <Field
                  label="Telefon (beruflich)"
                  placeholder="+41 12 123 12 13"
                  name="phone_work"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.phone_work}
                  error={
                    errors.phone_work && touched.phone_work && errors.phone_work
                  }
                />
              </div>
              <div className={classes.FlexRow}>
                <Field
                  label="Fax"
                  placeholder="+41 12 123 12 13"
                  name="fax"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.fax}
                  error={errors.fax && touched.fax && errors.fax}
                />

                <Field
                  label="E-Mail"
                  placeholder="mike@mueller.ch"
                  name="email"
                  onChange={(e) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.email}
                  error={errors.email && touched.email && errors.email}
                />
              </div>

              {helper.map((i) => (
                <section key={i}>
                  <h3 className={classes.FormSubtitle}>Fahrzeug {i}</h3>
                  <div className={classes.FlexRow}>
                    <Field
                      label="Modell"
                      placeholder="Modell"
                      name={`model${i}`}
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values[`model${i}`]}
                      error={
                        errors[`model${i}`] &&
                        touched[`model${i}`] &&
                        errors[`model${i}`]
                      }
                    />

                    <Field
                      label="Baujahr"
                      placeholder="Baujahr"
                      name={`year${i}`}
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values[`year${i}`]}
                      error={
                        errors[`year${i}`] &&
                        touched[`year${i}`] &&
                        errors[`year${i}`]
                      }
                    />

                    <Field
                      label="Fahrgestellnummer"
                      placeholder="Fahrgestellnummer"
                      name={`vin${i}`}
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values[`vin${i}`]}
                      error={
                        errors[`vin${i}`] &&
                        touched[`vin${i}`] &&
                        errors[`vin${i}`]
                      }
                    />
                  </div>
                </section>
              ))}

              <button className="Button" type="submit" disabled={isSubmitting}>
                Anmeldung abschicken
              </button>
            </form>
          )}
        </Formik>
      </main>
    </div>
  );
};

export default Mitglied;
